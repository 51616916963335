import { css, SerializedStyles } from "@emotion/react"

export const breakPoint = 1020

const blue = `#64B8C4`
const green = `#91A555`
const greenLight = `#b7e2c4`
const brown = `#583e23`
const red = `#f96e46`
const white = `#fff`
const black = `#333`
const gray = `#8F8F8F`
const grayLight = `#f1efed`
const font = black
export const color = {
  blue,
  green,
  greenLight,
  brown,
  red,
  white,
  black,
  gray,
  grayLight,
  font,
}

const sans = `"Noto Sans JP", "Helvetica Neue", Arial, YuGothic, "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif`
const decorate1 = `carme, ${sans}`
const serif = `"Noto Serif JP",游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif`
export const fontFamily = {
  sans,
  decorate1,
  serif,
}

const contentPC = `1280px`
const contentInner = `1080px`
const contentWide = `1720px`
const paddingSPNumber = 2.7
const paddingSP = paddingSPNumber + `vw`
const contentSP = 100 - paddingSPNumber * 2 + `vw`
export const width = {
  contentPC,
  contentInner,
  contentWide,
  paddingSPNumber,
  paddingSP,
  contentSP,
}

export const header = {
  height: `50px`,
}

type BrowserMode = `pc` | `sp`

export const mq = (mode: BrowserMode, props: SerializedStyles) => {
  switch (mode) {
    case "sp":
      return css`
        @media (max-width: ${breakPoint - 1}px) {
          ${props}
        }
      `
    case "pc":
      return css`
        @media (min-width: ${breakPoint}px) {
          ${props}
        }
      `
  }
}
