import React from "react"
import { Global, css } from "@emotion/react"
import { color, mq, fontFamily, header } from "../utils/style-components"
import "ress"

import "@fontsource/noto-sans-jp"
import "@fontsource/noto-sans-jp/700.css"
import "@fontsource/carme"

import Header from "./header"
import Footer from "./footer"

const Layout: React.FC = ({ children }) => {
  const fontSize = 16
  const lineHeight = 2.0
  return (
    <>
      <Global
        styles={css`
          html {
            font-size: 62.5%;
            ${mq(
              `pc`,
              css`
              scroll-padding-top: 170px;
              `
            )}
            ${mq(
              `sp`,
              css`
              scroll-padding-top: 30vw;
              `
            )}
          }
          body {
            font-size: ${fontSize}px;
            font-size: ${fontSize / 10}em;
            font-family: ${fontFamily.sans};
            color: ${color.font};
            line-height: ${lineHeight};
            letter-spacing: normal;
          }
          img {
            max-width: 100%;
          }
          a {
            color: inherit;
          }
          ${mq(
            `pc`,
            css`
            .sp {
              display: none;
            }
            `
          )}
          ${mq(
            `sp`,
            css`
            .pc {
              display: none;
            }
            `
          )}
        `}
      />
      <div
        css={css`
          position: relative;
        `}
      >
        <Header />
        {children}
        <Footer />
      </div>
    </>
  )
}

export default Layout
