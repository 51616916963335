import React from "react"
import Helmet from "react-helmet"

import { color } from "../utils/style-components"

type SEOProps = {
  title?: string
  description?: string
}

const SEO: React.FC<SEOProps> = ({ title, description }) => {
  const defaultTitle = `SDGs共創と実証実験の場 八丈島TENNEI［てんねい］`
  const defaultDescription = `離島が抱える様々な課題をクリエイティブの力で解決し、持続可能でより良い未来の実現を目指す。東京都八丈島に備えたオフィスで様々な課題に挑戦します。`

  const titlePrefix = `八丈島TENNEI［てんねい］`

  const siteTitle = title ? title + ` | ` + titlePrefix : defaultTitle
  const siteDescription = description ? description : defaultDescription

  return (
    <Helmet>
      <html lang="ja" />
      <title>{siteTitle}</title>
      <meta name="description" content={siteDescription} />
      <link rel="icon" href="favicon.svg" />
      <link rel="icon alternate" href="favicon.png" type="image/png" />
      <link rel="apple-touch-icon" href="apple-touch-icon.png" />
      <meta name="theme-color" content={color.blue} />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={siteDescription} />
      <meta name="twitter:title" content={siteTitle} />
    </Helmet>
  )
}
export default SEO
