import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

import MediaQuery from "react-responsive"

import { color, mq, breakPoint } from "../utils/style-components"

const Footer: React.FC = () => {
  return (
    <footer
      css={css`
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding-bottom: min(20px, 2vw);
        height: min(163px, 16vw);
        z-index: 50;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        &::before {
          display: block;
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-bottom: solid min(163px, 16vw) ${color.white};
          border-right: solid 50vw transparent;
          border-left: solid 50vw transparent;
          z-index: -1;
        }
      `}
    >
      <div
        css={css`
          font-weight: 700;
          line-height: 1;
          text-align: center;
          ${mq(
            `pc`,
            css`
              font-size: 1.4rem;
            `
          )}
          ${mq(
            `sp`,
            css`
              transform: scale(0.8);
              transform-origin: bottom center;
              font-size: min(1.8rem, 3.12vw);
              line-height: 1.2;
            `
          )}
        `}
      >
        &copy;FIELD MANAGEMENT EXPAND Inc.&nbsp;
        <br
          css={css`
            ${mq(
              `pc`,
              css`
                display: none;
              `
            )}
          `}
        />
        All rights reserved.
      </div>
      <MediaQuery minWidth={breakPoint}>
        <div
          css={css`
            padding: 1em 0 0;
          `}
        >
          <a
            href="https://group-fm.com/policy/"
            target="_blank"
            rel="noopener noreferrer"
            css={css`
              border-right: solid 1px ${color.black};
              border-left: solid 1px ${color.black};
              text-decoration: none;
              line-height: 1;
              padding: 0 1em;
              font-size: 1.4rem;
            `}
          >
            プライバシーポリシー
          </a>
        </div>
      </MediaQuery>
    </footer>
  )
}

export default Footer
